import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Homepage from './pages/Homepage/Homepage';
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Support from './pages/Support/Support';
import Privacy from './pages/Privacy/Privacy';

function App() {

  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Homepage/>}/>
        <Route path='/support' element={<Support/>}/>
        <Route path='/privacy' element={<Privacy/>}/>
      </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="colored"
      />
    </div>
  );
}

export default App;
